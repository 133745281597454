module.exports = [{
      plugin: require('/opt/app/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/app/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/app/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":[""],"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('/opt/app/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
