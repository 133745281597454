// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/app/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-tag-tsx": () => import("/opt/app/src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */),
  "component---src-templates-author-tsx": () => import("/opt/app/src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-page-tsx": () => import("/opt/app/src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("/opt/app/src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-index-tsx": () => import("/opt/app/src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-blog-tsx": () => import("/opt/app/src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-authors-tsx": () => import("/opt/app/src/templates/authors.tsx" /* webpackChunkName: "component---src-templates-authors-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/app/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/opt/app/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-affiliate-tsx": () => import("/opt/app/src/pages/affiliate.tsx" /* webpackChunkName: "component---src-pages-affiliate-tsx" */),
  "component---src-pages-brands-tsx": () => import("/opt/app/src/pages/brands.tsx" /* webpackChunkName: "component---src-pages-brands-tsx" */),
  "component---src-pages-faq-tsx": () => import("/opt/app/src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-list-management-tsx": () => import("/opt/app/src/pages/list-management.tsx" /* webpackChunkName: "component---src-pages-list-management-tsx" */),
  "component---src-pages-wheretofindus-tsx": () => import("/opt/app/src/pages/wheretofindus.tsx" /* webpackChunkName: "component---src-pages-wheretofindus-tsx" */)
}

